import * as THREE from 'three'


export default class Renderer 
{
    constructor(experience) {
        this.experience = experience
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene= this.experience.scene
        this.camera = this.experience.camera

        this.setInstance()
    }

    setInstance()
    {
            this.instance = new THREE.WebGLRenderer({
                canvas: this.canvas,
                alpha:true,
            })
            this.instance.setSize(this.sizes.width, this.sizes.height)
            this.instance.setPixelRatio(this.sizes.pixelRatio)
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)
    }

    update()
    {
        this.instance.render(this.scene, this.camera.instance)
    }
}