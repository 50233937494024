// jQuery.noConflict();
// // //scrollMagic: initi controller scroll
// var controller = new ScrollMagic.Controller();
// var blockTween = new TweenMax.to('.productPicture', {
//     scale:0.9,
//     y: 300,
// });

// var scene = new ScrollMagic.Scene({
//     triggerElement: '.section2',
//     reverse:true ,
//     triggerHook:0,
//     duration:'100%',     
// })

// .setTween(blockTween)
// .addTo(controller);
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener("load", function() {
    // do stuff here.
    //Section1 animation
    // gsap.from('.mainTitle', { 
    //     opacity:0,
    //     y:-50,
    //     duration:2,
    //     delay:0.2,
    // })

    // gsap.from('.mainText', { 
    //     opacity:0,
    //     duration:2,
    //     delay:0.8,
    // })

    // //section2 animation 
    // gsap.from('.canvas', { 
    //     scrollTrigger:{ trigger: '.product', 
    //     scroller: ".fullbody",
    //     scrub: true,
    //     toggleActions: "play none none reverse",
    //     start: '-20% bottom' ,
    //     end: "top 50%",
    // },
    //     x: 500
    // })

    // window.addEventListener('resize', ()=>{
    //     console.log("bonjour")
    // });

    // var mediaQueryList = window.matchMedia('(max-width: 700px)');
    if (window.matchMedia("(max-width: 700px)").matches) {
        // Viewport is less or equal to 700 pixels wide
        gsap.from('.scan', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                scrub: true,
                start: 'top 50%',
                end: 'top 30%',
            },
            x: -50,
        })

        gsap.from('.pay', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                start: 'top 50%',
                end: 'top 30%',
                scrub: true,
            },
            x: 50,
        })
        gsap.from('.go', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                scrub: true,
                start: 'top 50%',
                end: 'top 30%',
            },
            x: -50,
        })
        gsap.to('.haut-gauche', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '10% 70%',
                end: 'center 20%',
                scrub: true,
            },
            yPercent: -70,
            xPercent: -70,
        })

        gsap.to('.haut-droite', {
            scrollTrigger: {
                trigger: '.integrationDescription',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '10% 70%',
                end: 'center 20%',
                scrub: true,
            },
            yPercent: -70,
            xPercent: 70,
        })

        gsap.to('.bas-gauche', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '10% 70%',
                end: 'center 20%',
                scrub: true,
            },
            yPercent: 70,
            xPercent: -70,
        })


        gsap.to('.bas-droite', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '10% 70%',
                end: 'center 20%',
                scrub: true,
            },
            yPercent: 70,
            xPercent: 70,
        })



    } else {
        // Viewport is greater than 700 pixels wide

        gsap.from('.paraOuMission', {
            scrollTrigger: {
                trigger: '.paraOuMission',
                scroller: ".fullbody",
                scrub: true,
                pin: '.product',
                toggleActions: "play none none reverse",
                start: 'top bottom',
                end: "top 50%",
            },
            backgroundPositionY: '200%',
            y: 700,
        })

        gsap.to('.product', {
            scrollTrigger: {
                trigger: '.product',
                scroller: ".fullbody",
                scrub: true,
                toggleActions: "play none none reverse",
                start: 'center 70%',
                end: "center 30%",
            },
            // x:-300,
            // scale:0.7,
            transform: "matrix(0.70,0.00,0.00,0.70,-300,0)",
        })


        const averageTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.averageTimeText',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 50%',
                end: 'top 30%',
                scrub: true,
            }
        })
        averageTimeline.from('.averageTimeText', { x: 50, opacity: 0 })
            .from('.numberEleven', { y: 50, opacity: 0 })
            .from('.sablier', { x: 50, opacity: 0 })


        let slidingTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.textContainer',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: 0.2,
                start: 'top 50%',
                end: 'top 0%',
            },
        })
        slidingTimeline.to('.textContainer', { rotation: 10, y: -100 })
            .to('.textContainer2', { rotation: -10, y: -100 })

        // gsap.from('.averageTimeText',{
        //     scrollTrigger:{ trigger: '.averageTimeText', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     start: 'top 50%' ,
        //     scrub:true,
        //     },
        //     ease:'elastic',
        //     x:50,
        //     opacity:0,
        // })

        // gsap.from('.numberEleven',{
        //     scrollTrigger:{ trigger: '.averageTimeText', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     start: 'top 45%' ,
        //     scrub:true,
        //     },
        //     delay:0.3,
        //     ease:'elastic',
        //     y:50,
        //     opacity:0,
        // })


        // gsap.from('.sablier',{
        //     scrollTrigger:{ trigger: '.averageTimeText', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:true,
        //     start: 'top 40%' ,
        //     },
        //     ease:"elastic",
        //     x:50,
        //     opacity:0,
        // })

        // gsap.to('.textContainer', {
        //     scrollTrigger:{ trigger: '.textContainer', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:0.2,
        //     start: 'top 50%' ,
        //     end:'top 0%',
        //     },
        //     rotation:10,
        //     y:-100
        // })


        // gsap.to('.textContainer2', {
        //     scrollTrigger:{ trigger: '.textContainer', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:0.2,
        //     start: 'top 50%' ,
        //     end:'top 0%',
        //     },
        //     rotation:-10,
        //     y:-100
        // })

        gsap.from('.store', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                scrub: true,
                start: 'top 50%',
                end: 'top 30%',
            },
            x: 100,
        })

        gsap.from('.scan', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                scrub: true,
                start: 'top 50%',
                end: 'top 30%',
            },
            x: -100,
        })

        gsap.from('.pay', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                start: 'top 50%',
                end: 'top 30%',
                scrub: true,
            },
            x: 100,
        })


        gsap.from('.go', {
            scrollTrigger: {
                trigger: '.store',
                scroller: ".fullbody",
                start: 'top 50%',
                end: 'top 30%',
                scrub: true,
            },
            x: -100,
        })

        gsap.from('.imageProduct', {
            scrollTrigger: {
                trigger: '.imageProduct',
                scroller: ".fullbody",
                start: 'top 30%',
                end: 'top 10%',
                scrub: true,
            },
            yPercent: -20,
            xPercent: 20,
            opacity: 0.2,
            scale: 0.7
        })

        gsap.to('a', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 50%',
                end: 'top 0%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.iconic', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 50%',
                end: 'top 0%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.lineProgression', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'top 0%',
            },
            filter: "invert(100%)"
        })

        gsap.to('a', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 30%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.iconic', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 30%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.lineProgression', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 30%',
            },
            filter: "invert(100%)"
        })

        gsap.to('a', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 40%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.iconic', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 40%',
            },
            filter: "invert(100%)"
        })

        gsap.to('.lineProgression', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'bottom 40%',
            },
            filter: "invert(100%)"
        })



        gsap.to('a', {
            scrollTrigger: {
                trigger: '.section4',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'top 30%',
            },
            filter: "invert(100%)"
        })


        // gsap.from('.containerImageLeft', {
        //     scrollTrigger:{ trigger: '.title', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:0.6,
        //     pin:'',
        //     start: 'top bottom' ,
        //     end:'top 50%',
        //     markers:true,
        //     },
        //     y: 400,
        //     opacity:0,
        // })

        // gsap.from('.containerImageRight', {
        //     scrollTrigger:{ trigger: '.title', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:0.4,
        //     start: 'top 90%' ,
        //     end:'top 50%',
        //     markers:true,
        //     },
        //     y: 400,
        //     opacity:0,
        // })

        // gsap.from('.containerImageMiddle', {
        //     scrollTrigger:{ trigger: '.title', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:0.4,
        //     start: 'top 80%' ,
        //     end:'top 50%',
        //     markers:true,
        //     },
        //     y: 600,
        //     opacity:0,
        // })


        gsap.to('.productLeft', {
            scrollTrigger: {
                trigger: '.title',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                refreshPriority: 1,
                pin: ".productLeft ",
                start: 'top 50%',
                end: '+=1500',
            },
        })

        // gsap.to('.productTop', {
        //     scrollTrigger:{ trigger: '.title', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:true,
        //     pin:".productTop",
        //     start: 'top 60%' ,
        //     end:'+=1000',
        //     },
        // })

        // gsap.to('.productRight', {
        //     scrollTrigger:{ trigger: '.title', 
        //     scroller: ".fullbody",
        //     toggleActions: "play none none reverse",
        //     scrub:true,
        //     pin:".productRight",
        //     start: 'top 70%' ,
        //     end:'+=1300',
        //     },
        // })

        gsap.from('.title', {
            scrollTrigger: {
                trigger: '.title',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 80%',
                end: 'top 0%',
                pin: ".productRight",
            },
            duration: 0.5,
            y: 100,
            opacity: 0
        })

        gsap.from('.reminder', {
            scrollTrigger: {
                trigger: '.reminder',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 60%',
                end: 'top 0%',
                pin: ".productTop",
            },
            duration: 1,
            opacity: 0,
            y: -100,
        })

        gsap.from('.cutomsShoppingList', {
            scrollTrigger: {
                trigger: '.cutomsShoppingList',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 60%',
                end: 'top 0%',
            },
            duration: 1,
            opacity: 0,
            y: -100,
        })

        gsap.from('.e-ticket', {
            scrollTrigger: {
                trigger: '.e-ticket',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 60%',
                end: 'top 0%',
            },
            duration: 1,
            opacity: 0,
            y: -100,
        })

        gsap.from('.Loyaty-Card', {
            scrollTrigger: {
                trigger: '.Loyaty-Card',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 60%',
                end: 'top 0%',
            },
            duration: 1,
            opacity: 0,
            y: -100,
        })

        gsap.to('.haut-gauche', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '20% 70%',
                end: 'center 50%',
                scrub: true,
            },
            yPercent: -70,
            xPercent: -70,
        })

        gsap.to('.haut-droite', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '20% 70%',
                end: 'center 50%',
                scrub: true,
            },
            yPercent: -70,
            xPercent: 70,
        })

        gsap.to('.bas-gauche', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '20% 70%',
                end: 'center 50%',
                scrub: true,
            },
            yPercent: 70,
            xPercent: -70,
        })


        gsap.to('.bas-droite', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: '20% 70%',
                end: 'center 50%',
                scrub: true,
            },
            yPercent: 70,
            xPercent: 70,
        })

        gsap.from('.titleDash', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'center 70%',
                end: 'center 40%',
            },
            duration: 0.5,
            opacity: 0,
            y: -100
        })


        gsap.from('.timeIntegration', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 50%',
                end: 'center 40%',
            },
            duration: 0.5,
            delay: 0.2,
            opacity: 0,
            y: 90
        })

        gsap.from('.inetgrationLine', {
            scrollTrigger: {
                trigger: '.section5',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'center 50%',
                end: 'center 40%',
            },
            duration: 0.3,
            opacity: 0,
        })

        gsap.to('a', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'top 40%',
            },
            filter: "invert(0%)"
        })

        gsap.to('.lineProgression', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 60%',
                end: 'top 40%',
            },
            filter: "invert(0%)"
        })

        gsap.to('.iconic', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                scrub: true,
                start: 'top 50%',
                end: 'top 40%',
            },
            filter: "invert(0%)",
            pause: true
        })


        gsap.from('.profilPic', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 30%',
                scrub: true,
                end: 'top 10%',
            },
            opacity: 0,
            scale: 0
        })

        gsap.from('.lineText', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 20%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })

        gsap.from('.equipeTitle', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 18%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })

        gsap.from('.ligne1', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 20%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })
        gsap.from('.ligne2', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 17%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })

        gsap.from('.ligne3', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 14%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })


        gsap.from('.ligne4', {
            scrollTrigger: {
                trigger: '.section6',
                scroller: ".fullbody",
                toggleActions: "play none none reverse",
                start: 'top 10%',
                end: 'top 0%',
            },
            opacity: 0,
            duration: 0.2,
            ease: 'linear',
            y: 50,
        })



        // gsap.from('.reminder', {
        //     scrollTrigger:{ trigger: '.reminder', 
        //     scroller: ".fullbody",
        //     toggleActions: "play pause none reverse",
        //     pin:".title",
        //     start: 'bottom 30%' ,
        //     end:'bottom 0%',
        //     markers:true,
        //     },
        //     duration:1,
        //     opacity:0
        // })



        // gsap.from('.cutomsShoppingList', {
        //     scrollTrigger:{ trigger: '.cutomsShoppingList', 
        //     scroller: ".fullbody",
        //     toggleActions: "play pause none reverse",
        //     pin:".reminder",
        //     start: 'bottom 50%' ,
        //     end:'bottom 0%',
        //     markers:true,
        //     },
        //     duration:1,
        //     opacity:0
        // })



    }
    gsap.to('.section4', {
        scrollTrigger: {
            trigger: '.section4',
            scroller: ".fullbody",
            toggleActions: "play none none reverse",
            scrub: true,
            start: 'top 60%',
            end: 'top 30%',
        },
        backgroundColor: '#fff',
    })

    gsap.to('.section3', {
        scrollTrigger: {
            trigger: '.section4',
            scroller: ".fullbody",
            toggleActions: "play none none reverse",
            scrub: true,
            start: 'top 60%',
            end: 'top 30%',
        },
        backgroundColor: '#fff',
    })
    gsap.to('.section6', {
        scrollTrigger: {
            trigger: '.section6',
            scroller: ".fullbody",
            toggleActions: "play none none reverse",
            start: 'top 60%',
            end: 'top 0%',
            scrub: 0.2,
        },
        backgroundColor: "rgba(0,0,0,0)",
    })
    gsap.to('.section5', {
        scrollTrigger: {
            trigger: '.section6',
            scroller: ".fullbody",
            toggleActions: "play none none reverse",
            start: 'top 60%',
            end: 'top 0%',
            scrub: 0.2,
        },
        backgroundColor: "rgba(0,0,0,0)",
    })









});