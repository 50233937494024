import $ from "jquery";

//let legacySection = document.getElementById("legacy")
let troisBar = document.getElementById("troisBar")
let btnClose = document.getElementById("cross")


$("#troisBar").click(function() {
    $("#legacy").fadeIn();
    $("#legacy").css("display", "flex");
    $("body").css("cursor", "pointer");
    $("#cross").click(function() {
        $("#legacy").fadeOut();
        $("body").css("cursor", "none");
    });
});

// The locale our app first shows
const defaultLocale = "fr";
// The active locale
let locale;
// Gets filled with active locale translations
let translations = {};
// When the page content is ready...
document.addEventListener('DOMContentLoaded', () => {
    setLocale(defaultLocale);
    $("#lang-fr img").css("border-color", "#00ccff");
    $('footer').fadeOut();
});
// Load translations for the given locale and translate  the page to this locale
async function setLocale(newLocale) {
    if (newLocale === locale) return;
    const newTranslations = await fetchTranslationsFor(newLocale);
    locale = newLocale;
    translations = newTranslations;
    translatePage();
}
// Retrieve translations JSON object for the given locale over the network
async function fetchTranslationsFor(newLocale) {
    const response = await fetch(`./lang/${newLocale}.json`);
    return await response.json();
}
// Replace the inner text of each element that has a data-i18n-key attribute with the translation correspondingto its data-i18n-key
function translatePage() {
    document
        .querySelectorAll("[data-i18n-key]")
        .forEach(translateElement);
}
// Replace the inner text of the given HTML element with the translation in the active locale
function translateElement(element) {
    const key = element.getAttribute("data-i18n-key");
    const translation = translations[key];
    element.innerText = translation;
}

$("#lang-fr").click(
    function() {
        setLocale("fr");
        $("#lang-fr img").css("border-color", "#00ccff");
        $("#lang-en img").css("border-color", "#fff");
    }
)
$("#lang-en").click(
    function() {
        setLocale("en");
        $("#lang-en img").css("border-color", "#00ccff");
        $("#lang-fr img").css("border-color", "#fff");
    }
)

$(".fullbody").bind('scroll', function() {
    if ($(".fullbody").scrollTop() > 120) {
        $('#langue').fadeOut();
        $('footer').fadeIn();
    } else {
        $('#langue').fadeIn();
        $('footer').fadeOut();
    }
});