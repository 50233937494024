
import * as THREE from 'three'
import EventEmitter from '../Utils/EvenEmitter.js'




export default class World extends EventEmitter
{
    constructor(experience) 
    {
        super()
        this.experience = experience
        this.scene = this.experience.scene
        this.debug = this.experience.debug

        //Galaxy
        this.parameters = {}
        this.parameters.count = 60000
        this.parameters.size = 0.006
        this.parameters.radius = 10
        this.parameters.branches = 5
        this.parameters.spin = -1.02
        this.parameters.randomness = 5
        this.parameters.epaisseur = 5.5
        this.parameters.randomnessPower = 1.5
        this.parameters.insideColor = "#ff0066"
        this.parameters.outsideColor = '#009dff'

        this.geometry = null
        this.material = null
        this.points = null

        /*
        //Test Mesh
        const testMesh = new THREE.Mesh(
            new THREE.BoxGeometry(1,1,1),
            new THREE.MeshBasicMaterial({wireframe:true})
        )

        this.scene.add(testMesh)*/

        this.generateGalaxy()

         // Debug
         if(this.debug.active)
         {
            this.debugFolder = this.debug.ui.addFolder('parameters')
            this.debugFolder.add(this.parameters, 'count').min(0).max(100000).step(0.5).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'size').min(0).max(0.1).step(0.001).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'radius').min(0).max(10).step(0.001).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'branches').min(0).max(5).step(1).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'spin').min(-5).max(5).step(0.01).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'randomness').min(0).max(5).step(0.001).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'epaisseur').min(0).max(10).step(0.1).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.add(this.parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.addColor(this.parameters, 'insideColor').onFinishChange(() => { this.generateGalaxy() })
            this.debugFolder.addColor(this.parameters, 'outsideColor').onFinishChange(() => { this.generateGalaxy() })
         }
    }


    generateGalaxy (geometry, material, points)
        {
            /**
             * Destroy all galaxy
             */
 

            if(this.points !== null)
            {
                console.log(this.geometry, this.material, this.scene)
                this.geometry.dispose()
                this.material.dispose()
                this.scene.remove(this.points)
            }

            //first of all we need a geometry material 
            /**
             * Geometry
             */
            this.geometry = new THREE.BufferGeometry(15, 32, 16)
            const positions = new Float32Array(this.parameters.count  * 3) //because each element have actually  xyz
            const colors = new Float32Array(this.parameters.count * 3)
            for (let i = 0 ; i < this.parameters.count ; i++)
            {
                const indice  = i * 3
                const radius = Math.random() * this.parameters.radius
                const spinAngle = radius * this.parameters.spin
                const branchAngle = ( i % this.parameters.branches) / this.parameters.branches * Math.PI * 2
                //definie THREE.js Color
                const colorInside = new THREE.Color(this.parameters.insideColor)
                const colorOutside = new THREE.Color(this.parameters.outsideColor)


                /*
                const randomX = (Math.random() - 0.5) * parameters.randomness
                const randomY = (Math.random() - 0.5) * parameters.randomness
                const randomZ = (Math.random() - 0.5) * parameters.randomness
        */
                const randomX = Math.pow(Math.random()+0.1, this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1)
                const randomY = Math.pow(Math.random()+0.1, this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : 1)
                const randomZ = Math.pow(Math.random()+0.1, this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1)

                positions[indice    ] = Math.cos(branchAngle + spinAngle) * radius + randomX * this.parameters.randomness
                positions[indice + 1] = 0 + randomY * this.parameters.epaisseur
                positions[indice + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ * this.parameters.randomness

                //colors
                const mixedColor = colorInside.clone()
                mixedColor.lerp(colorOutside, radius / this.parameters.radius)

                colors[indice    ] = mixedColor.r
                colors[indice + 1] = mixedColor.g 
                colors[indice + 2] = mixedColor.b 
            }
            this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
            this.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

            /**
             * Material
             */
                this.material = new THREE.PointsMaterial({
                    size: this.parameters.size,
                    sizeAttenuation: true,
                    depthWrite: true,
                    blending: THREE.AdditiveBlending, 
                    vertexColors:true,
                })
            this.points = new THREE.Points(this.geometry, this.material)
            this.scene.add(this.points)
        }

        update()
        {
            this.points.rotation.y += 0.00003
        }

        rotateValue(valueX, valueY)
        {
            this.points.rotation.z = valueX * 0.5
            this.points.rotation.x = -valueX * 0.1
            this.points.position.x = -valueY * 0.5 
        }

        scrollValue(scrollvalueY)
        {
            this.points.position.y = scrollvalueY / 200
            this.points.position.z =  scrollvalueY / 500
        }
}