import * as THREE from 'three'
import EventEmitter from './Utils/EvenEmitter'



export default class Camera extends EventEmitter 
{
    constructor(experience) 
    {
       super()
       this.experience = experience
       this.sizes = this.experience.sizes
       this.scene= this.experience.scene
       this.canvas = this.experience.canvas
       this.cursor =  {
        x:0,
        y:0,
    }
        this.elementScrolling = document.querySelector(".fullbody");
        this.scrollY = this.elementScrolling.scrollTop
       this.mouveMoving()
       this.scrollYListenning()
       this.setInstance()
    }

    mouveMoving() 
    {
        window.addEventListener('mousemove', (event)=>
        {
            this.cursor.x = event.clientX / this.sizes.width - 0.5
            this.cursor.y = event.clientY / this.sizes.height - 0.5
           // console.log(this.cursor.x, this.cursor.y)
            this.trigger('mousemove')
        })
    }

    scrollYListenning()
    {

        this.elementScrolling.addEventListener('scroll', () => 
        {
            this.scrollY = this.elementScrolling.scrollTop
            this.trigger('scroll')
        })
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(
            90,
             this.sizes.width / this.sizes.height, 
             0.1,
              100)
        // this.instance.position.set(0.8164063311881444,-0.01076686351562516,6.546161375381034)
        this.instance.position.set(0.8164063311881444,0.51076686351562516,2.546161375381034)
        this.scene.add(this.instance)
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.instance.rotation.z = -this.cursor.x  * 0.4
    }

}