


   var mediaQueryList = window.matchMedia('(max-width: 700px)');
   if (window.matchMedia("(max-width: 700px)").matches) {
       // Viewport is less or equal to 700 pixels wide

     } else {
let innerCursor =document.querySelector('.inner-cursor')
let outerCursor  = document.querySelector('.outer-cursor')


let link = Array.from(document.querySelectorAll("a"));
link.forEach(lien => {
    lien.addEventListener('mouseover', () =>
        {
        console.log("hover")
        innerCursor.classList.add('grow')
        outerCursor.style.display="none";
        }
    )
    lien.addEventListener('mouseleave', () =>
    {
    console.log("leave")
    innerCursor.classList.remove('grow')
    outerCursor.style.display="block";
    }
)
}
)


let link2 = Array.from(document.querySelectorAll("downlaod"));
link2.forEach(lien => {
    lien.addEventListener('mouseover', () =>
        {
        console.log("hover")
        innerCursor.classList.add('grow2')
        outerCursor.style.display="none";
        }
    )
    lien.addEventListener('mouseleave', () =>
    {
    console.log("leave")
    innerCursor.classList.remove('grow2')
    outerCursor.style.display="block";
    }
)
}
)

let scanFinder = Array.from(document.querySelectorAll("scanActive"));
scanFinder.forEach(lien => {
    lien.addEventListener('mouseover', () =>
        {
        console.log("hover")
        innerCursor.classList.add('growScanner')
        outerCursor.style.display="none";
        }
    )
    lien.addEventListener('mouseleave', () =>
    {
    console.log("leave")
    innerCursor.classList.remove('growScanner')
    outerCursor.style.display="block";
    }
)
}
)

let scan = Array.from(document.querySelectorAll("scan"));
scan.forEach(lien => {
    lien.addEventListener('mouseover', () =>
        {
        console.log("hover")
        innerCursor.classList.add('growScannerSuper')
        outerCursor.style.display="none";
        }
    )
    lien.addEventListener('mouseleave', () =>
    {
    console.log("leave")
    innerCursor.classList.remove('growScannerSuper')
    outerCursor.style.display="block";
    }
)
}
)




//mouse
document.addEventListener('mousemove', moveCursor);
function moveCursor(e){
    let x = e.clientX;
    let y = e.clientY;
    innerCursor.style.left = `${x}px`;
    innerCursor.style.top = `${y}px`;
    //outerCursor.style.left = `${x}px`;
    //outerCursor.style.top = `${y}px`;
}


     }