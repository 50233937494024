import EventEmitter from "./EvenEmitter";


export default class Time extends EventEmitter 
{
    constructor()
   {
       super();

       //Setup;

       this.start = Date.now()
       this.elapsed = 0
       this.delta = 16
       
       window.requestAnimationFrame(() => 
       {
           this.tick()
       })
   }

   tick()
   {
        const currentTime = Date.now()
        this.delta = currentTime - this.currentTime
        this.current = currentTime
        this.elapsed = this.current - this.start

        this.trigger('tick')

       window.requestAnimationFrame(() => 
       {
           this.tick()
       })

   }
}