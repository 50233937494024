import './style.css'

import Experience from './Experience/Experience.js'
import './Js/Section1/Cursor';
import './Js/Section1/Line';
import './Js/Fade.js';
import './Js/legacy.js';
import './Js/section3/sticky';
const canvas = document.querySelector('canvas.webgl')
const htmlBody = document.querySelector('html')
const experience = new Experience(canvas)



//double click to resize
window.addEventListener('dblclick', () => {
        if (!document.fullscreenElement) {
            htmlBody.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    })
    //Doesn't idsplay the cursor
document.body.style.cursor = 'none';

const wait = (delay = 0) =>
    new Promise(resolve => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) =>
    (typeof elementOrSelector === 'string' ?
        document.querySelector(elementOrSelector) :
        elementOrSelector
    ).style.display = visible ? 'block' : 'none';

setVisible('#page', false);
setVisible('#loading', true);


document.addEventListener('DOMContentLoaded', () => {
    wait(5000).then(() => {
        setVisible('#page', true);
        setVisible('#loading', false);
    });
});




// import './style.css'
// import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import * as dat from 'lil-gui'
// import { lerp } from 'three/src/math/MathUtils'




// const ZatPayColor = {
//     blue: '#1A3663', 
//     pink : '#ED869E',
//     pink2:"#ff3898",
// }



// /**
//  * Base
//  */
// // Debug
// const gui = new dat.GUI()

// // Canvas
// const canvas = document.querySelector('canvas.webgl')

// // Scene
// const scene = new THREE.Scene()



// const parameters = {}
// parameters.count = 54518
// parameters.size = 0.008
// parameters.radius = 7
// parameters.branches = 3
// parameters.spin = 1
// parameters.randomness = 0.45
// parameters.epaisseur = 5
// parameters.randomnessPower = 7
// parameters.insideColor = "#ff297e"
// parameters.outsideColor = '#0aceff'


// let geometry = null
// let material = null
// let points = null
// let scrollY = window.scrollY
// window.addEventListener('scroll', () => 
// {
//     scrollY = window.scrollY

//     console.log(scrollY)
// })

// /**
//  * Galaxy
//  */

// const generateGalaxy = () => 
// {
//     /**
//      * Destroy all galaxy
//      */
//     if(points !== null)
//     {
//         geometry.dispose()
//         material.dispose()
//         scene.remove(points)
//     }

//     //first of all we need a geometry material 
//     /**
//      * Geometry
//      */
//     geometry = new THREE.BufferGeometry(15, 32, 16)
//     const positions = new Float32Array(parameters.count  * 3) //because each element have actually  xyz
//     const colors = new Float32Array(parameters.count * 3)
//     for (let i = 0 ; i < parameters.count ; i++)
//     {
//         const indice  = i * 3
//         const radius = Math.random() * parameters.radius
//         const spinAngle = radius * parameters.spin
//         const branchAngle = ( i % parameters.branches) / parameters.branches * Math.PI * 2
//         //definie THREE.js Color
//         const colorInside = new THREE.Color(parameters.insideColor)
//         const colorOutside = new THREE.Color(parameters.outsideColor)


//         /*
//         const randomX = (Math.random() - 0.5) * parameters.randomness
//         const randomY = (Math.random() - 0.5) * parameters.randomness
//         const randomZ = (Math.random() - 0.5) * parameters.randomness
// */
//         const randomX = Math.pow(Math.random()+0.1, parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1)
//         const randomY = Math.pow(Math.random()+0.1, parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : 1)
//         const randomZ = Math.pow(Math.random()+0.1, parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1)

//         positions[indice    ] = Math.cos(branchAngle + spinAngle) * radius + randomX * parameters.randomness
//         positions[indice + 1] = 0 + randomY * parameters.epaisseur
//         positions[indice + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ * parameters.randomness

//         //colors
//         const mixedColor = colorInside.clone()
//         mixedColor.lerp(colorOutside, radius / parameters.radius)

//         colors[indice    ] = mixedColor.r
//         colors[indice + 1] = mixedColor.g 
//         colors[indice + 2] = mixedColor.b 
//     }
//     geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
//     geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

//     /**
//      * Material
//      */
//          material = new THREE.PointsMaterial({
//             size: parameters.size,
//             sizeAttenuation: true,
//             depthWrite: true,
//             blending: THREE.AdditiveBlending, 
//             vertexColors:true,
//         })
//     points = new THREE.Points(geometry, material)
//     scene.add(points)

//     /**
//      * Color
//      */


// }
// generateGalaxy()

// gui.add(parameters, 'count').min(0).max(100000).step(0.5).onFinishChange(generateGalaxy)
// gui.add(parameters, 'size').min(0).max(0.1).step(0.001).onFinishChange(generateGalaxy)
// gui.add(parameters, 'radius').min(0).max(10).step(0.001).onFinishChange(generateGalaxy)
// gui.add(parameters, 'branches').min(0).max(5).step(1).onFinishChange(generateGalaxy)
// gui.add(parameters, 'spin').min(-5).max(5).step(0.01).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomness').min(0).max(5).step(0.001).onFinishChange(generateGalaxy)
// gui.add(parameters, 'epaisseur').min(0).max(10).step(0.1).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'insideColor').onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'outsideColor').onFinishChange(generateGalaxy)




// /**
//  * Test cube
//  */
// const cube = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1),
//     new THREE.MeshBasicMaterial()
// )
// //scene.add(cube)

// //Mouse Moving element
// const cursor = {
//     x:0,
//     y:0,
// }
// window.addEventListener('mousemove', (event)=>
// {
//     cursor.x = event.clientX / sizes.width - 0.5
//     cursor.y = event.clientY / sizes.height - 0.5
//     console.log(cursor.x, cursor.y)
// })

// /**
//  * Sizes
//  */
// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight
// }

// window.addEventListener('resize', () =>
// {
//     // Update sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()

//     // Update renderer
//     renderer.setSize(sizes.width, sizes.height)
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// })

// /**
//  * Camera
//  */
// // Base camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.x = 2.5164063311881444
// camera.position.y =  -0.01076686351562516
// camera.position.z =  4.546161375381034
// scene.add(camera)

// // Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true  

// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//     canvas: canvas,
//     alpha:true
// })
// renderer.setSize(sizes.width, sizes.height)
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



// /**
//  * Animate
//  */
// const clock = new THREE.Clock()

// const tick = () =>
// {
//     const elapsedTime = clock.getElapsedTime()


//     // Update controls
//     //controls.update()
//     points.rotation.y += 0.00006
//     camera.rotation.z = -cursor.x  *0.5
//     points.rotation.z = cursor.x  * 0.5
//     points.position.x = - cursor.y 
//     points.position.y = scrollY / 200
//     camera.position.y =  - scrollY / 2000
//     //camera.lookAt(points.position)

//     //console.log(camera.position)

//     // Render
//     renderer.render(scene, camera)
//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }

// tick()


// //x: 2.5164063311881444, y: -0.01076686351562516, z: 4.546161375381034