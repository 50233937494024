import * as THREE from 'three'
import Sizes from "./Utils/Sizes"
import Time from "./Utils/Time"
import Debug from './Utils/Debug'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'


export default class Experience 
{
    constructor(canvas) 
    {
        //global access in the console
        window.experience = this

        //Options
        this.canvas = canvas

        //Setup Sizes
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.camera = new Camera(this)
        this.renderer = new Renderer(this)
        this.world = new World(this)

        //adding listenner
        this.sizes.on('resize', () => 
        {
            this.resize();
        })

        this.time.on('tick', () =>
        {
            this.update()
        })

        this.camera.on('mousemove', ()=> 
        {
            this.world.rotateValue(this.camera.cursor.x, this.camera.cursor.y)
        })

        this.camera.on('scroll', ()=> 
        {
            this.world.scrollValue(this.camera.scrollY)
        })
        
    }

    resize() 
    {
        this.camera.resize()
        this.renderer.resize()
    }

    update()
    {
        this.camera.update()
        this.renderer.update()
        this.world.update()
    }
}